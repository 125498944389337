import "./App.scss";
import { lazy, Suspense } from "react";


import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';


import PublicRoute from './routes/publicRoute';


const Home = lazy(() => import('./pages/home'));
const BlogPage  = lazy(() => import('./pages/blogPage'));
const ContactUsPage  = lazy(() => import('./pages/contactUsPage'));
const AboutusPage = lazy(() => import('./pages/aboutusPage'));
const BlogDetailFirstPage = lazy(() => import('./pages/blogDetailFirst'));


// const NoFoundComponent = lazy(() => import('components/NoFoundComponent'));

const App = () => {


  let isAuthenticated = false

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>

        
        <Switch>
          <PublicRoute
            path="/home"
            isAuthenticated={isAuthenticated}  >
            <Home />
          </PublicRoute>

          <PublicRoute
            path="/blog"
            isAuthenticated={isAuthenticated}  >
            <BlogPage />
          </PublicRoute>

          <PublicRoute
            path="/contactus"
            isAuthenticated={isAuthenticated}  >
            <ContactUsPage />
          </PublicRoute>

          <PublicRoute
            path="/aboutus"
            isAuthenticated={isAuthenticated}  >
            <AboutusPage />
          </PublicRoute>

          <PublicRoute
            path="/firstblog"
            isAuthenticated={isAuthenticated}  >
            <BlogDetailFirstPage />
          </PublicRoute>

          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;