import 'dotenv/config'
import ReactDOM from 'react-dom';
import './index.scss';
import './responsive.css';

import 'bootstrap/dist/css/bootstrap.min.css';

 import 'bootstrap/dist/js/bootstrap.bundle.min'; 
 import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import App from './App';




ReactDOM.render(

  <>
   
      <App />
   
  </>,
  document.getElementById('root')
);

